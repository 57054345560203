import(/* webpackMode: "eager", webpackExports: ["Breadcrumb"] */ "__barrel_optimize__?names=Breadcrumb!=!/builds/vrt/vrt/frontend/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/assets/images/social_share.png");
;
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/atoms/BannerButton.tsx");
;
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/atoms/DrupalImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/vrt/vrt/frontend/src/components/atoms/ListButton.tsx");
;
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/blocks/HeaderSubsite.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/vrt/vrt/frontend/src/components/edubox/EduboxPage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FaqOverviewList"] */ "/builds/vrt/vrt/frontend/src/components/molecules/FaqOverviewList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/vrt/vrt/frontend/src/components/molecules/HeroCards.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/vrt/vrt/frontend/src/components/molecules/HeroLanding.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/vrt/vrt/frontend/src/components/molecules/HeroSubsite.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsOverviewList"] */ "/builds/vrt/vrt/frontend/src/components/molecules/NewsOverviewList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsOverviewListWithoutFilters"] */ "/builds/vrt/vrt/frontend/src/components/molecules/NewsOverviewListWithoutFilters.tsx");
;
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/molecules/TeaserContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TocList"] */ "/builds/vrt/vrt/frontend/src/components/molecules/TocList.tsx");
;
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/organisms/ArticleSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MaxSlider"] */ "/builds/vrt/vrt/frontend/src/components/organisms/MaxSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NwsSlider"] */ "/builds/vrt/vrt/frontend/src/components/organisms/NwsSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PodcastSlider"] */ "/builds/vrt/vrt/frontend/src/components/organisms/PodcastSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RelatedSlider"] */ "/builds/vrt/vrt/frontend/src/components/organisms/RelatedSlider.tsx");
;
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/organisms/ShareButtons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SporzaSlider"] */ "/builds/vrt/vrt/frontend/src/components/organisms/SporzaSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AccordeonParagraph"] */ "/builds/vrt/vrt/frontend/src/components/paragraphs/AccordeonParagraph.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PartnerBlockParagraph"] */ "/builds/vrt/vrt/frontend/src/components/paragraphs/PartnerBlockParagraph.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideoParagraph"] */ "/builds/vrt/vrt/frontend/src/components/paragraphs/VideoParagraph.tsx");
;
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/tracking/BannerVisibilityTracker.tsx");
;
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/tracking/PageImpressionTracking.tsx");
;
import(/* webpackMode: "eager" */ "/builds/vrt/vrt/frontend/src/components/tracking/VerticalListVisibilityTracker.tsx");
